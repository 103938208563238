import React, { useEffect } from "react"
import SEO from "../../components/seo"

// 🎨 UI
import SinglePageWrapper from "../../components/Contents/SinglePageWrapper"
import ProjektzielTeaserImage from "../../components/Contents/Projektziel/ProjektzielTeaserImage"
import ProjektzielHeader from "../../components/Contents/Projektziel/ProjektzielHeader"
import ScrollTopButton from "../../components/ScrollTopButton"
import { graphql } from "gatsby"
import Inhalt from "../../components/Contents/Inhalt"
import { useDispatch } from "react-redux"

export default function Forschungen({ data }) {
  const post = data.wpWissenschaft
  const settings = data.wpModeSettings
  const sendActionToStore = useDispatch()

  useEffect(() => {
    sendActionToStore({
      type: "change/translatedSchwerpunktSlug",
      payload: post.translated[0]?.slug,
    })
  }, [post.translated, sendActionToStore])
  // console.log("POST", post)
  return (
    <>
      <SEO title={post.title} />
      <ScrollTopButton />

      <SinglePageWrapper background="blue.50">
        <ProjektzielHeader
          projektName={settings.wissenschaft}
          title={post.title}
          summaryName={
            post.acf_forschung?.projektbeschreibung.textProjektbeschreibungTitle
          }
          summary={
            post.acf_forschung?.projektbeschreibung.textProjektbeschreibungText
          }
          projektbeteiligteName={settings.projektbeteiligte}
          forschungsbeteiligte={post.acf_forschung?.groupProjektbeteiligte}
        />

        <ProjektzielTeaserImage image={post.acf_forschung.imageFeatureImage} />

        {/* 📰 Inhalt */}
        <Inhalt
          repeater={post.acf_content.repeaterDetails}
          smallWidth={true}
          projektZiel={true}
          forschungDetail={false}
          galleryBelow={false}
          onColor={true}
        />
      </SinglePageWrapper>
    </>
  )
}

export const query = graphql`
  query ForschungContent($lang: String!, $slug: String!) {
    wpModeSettings(
      slug: { eq: "forschung" }
      locale: { locale: { eq: $lang } }
    ) {
      acf_research_settings {
        wissenschaft
        projektbeteiligte
      }
    }
    wpWissenschaft(slug: { eq: $slug }, locale: { locale: { eq: $lang } }) {
      title
      translated {
        slug
      }
      acf_content {
        repeaterDetails {
          textSectionTitel
          flexibleLayoutContentarea {
            ... on WpWissenschaft_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Link {
              fieldGroupName
              linkText
              linkUrl
            }
            ... on WpWissenschaft_AcfContent_repeaterDetails_FlexibleLayoutContentarea_AbsatzMitGallerie {
              wysiwygContent
              groupGallery {
                galleryGallery {
                  altText
                  caption
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1200, quality: 75) {
                        src
                        srcSet
                      }
                    }
                    publicURL
                  }
                  acf_media {
                    textImageCredit
                  }
                }
                columns
              }
              linkText
              linkUrl
              fieldGroupName
            }
            ... on WpWissenschaft_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Absatz {
              wysiwygContent
              fieldGroupName
              linkText
              linkUrl
            }
            ... on WpWissenschaft_AcfContent_repeaterDetails_FlexibleLayoutContentarea_VerwandteProjekte {
              fieldGroupName
              relationProject {
                ... on WpProjektziel {
                  id
                  slug
                  acf_projektziel_content {
                    groupLeft {
                      relationMobilitaetsschwerpunkt {
                        ... on WpSchwerpunkt {
                          id
                          slug
                        }
                      }
                      imageFeaturedImage {
                        altText
                        localFile {
                          childImageSharp {
                            fluid(maxWidth: 1200, quality: 75) {
                              src
                              srcSet
                            }
                          }
                          publicURL
                        }
                      }
                      textSummary
                      textProjektname
                    }
                    fieldGroupName
                  }
                }
              }
            }
            ... on WpWissenschaft_AcfContent_repeaterDetails_FlexibleLayoutContentarea_VerwandteForschung {
              fieldGroupName
              relationForschung {
                ... on WpWissenschaft {
                  id
                  acf_forschung {
                    relationSchwerpunktthema {
                      ... on WpWissenschaftsschwerpunkt {
                        id
                        slug
                        title
                      }
                    }
                    projektbeschreibung {
                      fieldGroupName
                      textProjektbeschreibungText
                      textProjektbeschreibungTitle
                    }
                    imageFeatureImage {
                      altText
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 1200, quality: 75) {
                            src
                            srcSet
                          }
                        }
                        publicURL
                      }
                    }
                    groupProjektbeteiligte {
                      disziplin {
                        name
                      }
                    }
                  }
                  title
                  slug
                }
              }
            }
            ... on WpWissenschaft_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Registerkarten {
              fieldGroupName
              repeaterRegisterkarten {
                textContent
                textTitle
              }
            }
            ... on WpWissenschaft_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Download {
              fieldGroupName
              anhang {
                ... on WpWissenschaft_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Download_Anhang_Datei {
                  buttonText
                  fieldGroupName
                  datei {
                    localFile {
                      publicURL
                    }
                  }
                }
                ... on WpWissenschaft_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Download_Anhang_Url {
                  buttonText
                  fieldGroupName
                  url
                }
              }
            }
          }
          fieldGroupName
        }
      }
      acf_forschung {
        groupProjektbeteiligte {
          textAutoren {
            name
          }
          textInstitution {
            name
            acf_institute {
              enTranslation {
                name
                beschreibung
              }
            }
          }
          disziplin {
            name
            tax_translation {
              enTranslation
            }
          }
        }
        imageFeatureImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 75) {
                src
                srcSet
              }
            }
            publicURL
          }
        }
        projektbeschreibung {
          textProjektbeschreibungText
          textProjektbeschreibungTitle
        }
        relationSchwerpunktthema {
          ... on WpWissenschaftsschwerpunkt {
            id
            title
          }
        }
      }
    }
  }
`
